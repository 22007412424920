import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useApi } from '@backstage/core-plugin-api';
import Alert from '@mui/lab/Alert';
import { IncidentsTable } from './IncidentsTable';
import { opsgenieApiRef } from '../../api';
import { ContentWrapper, useUserTeams } from 'plugin-ui-components';
import SettingsIcon from '@mui/icons-material/Settings';
import type { Incident, IncidentWithTeams, Team } from '../../types';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { InfoPanel } from '../InfoPanel';
import { useAsync } from 'react-use';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { GroupFilters } from './GroupFilters';
import { type IOption, MultiSelectFilter, SelectFilter } from './filters';
import {
  ArrayParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { getProgress, getProgressPercentage, getTeamOptionName } from './utils';
import cloneDeep from 'lodash/cloneDeep';
import Card from '@mui/material/Card';

const DefaultArray = withDefault(ArrayParam, []);
const severityOptions: IOption[] = [
  { value: '', label: 'All' },
  { value: 'SEV1', label: 'SEV1' },
  { value: 'SEV2', label: 'SEV2' },
  { value: 'SEV3', label: 'SEV3' },
];
const statusOptions: IOption[] = [
  { value: '', label: 'All' },
  { value: 'closed', label: 'Closed' },
  { value: 'resolved', label: 'Resolved' },
];

dayjs.extend(localizedFormat);

type GroupFilterOptions = 'mine' | 'all';
type IData = { mine: IncidentWithTeams[]; all: IncidentWithTeams[] };

export function IncidentsList() {
  const [view, setView] = useState<GroupFilterOptions>('mine');
  const opsGenieApi = useApi(opsgenieApiRef);
  const catalogApi = useApi(catalogApiRef);
  const [queryParams, setQueryParams] = useQueryParams({
    severity: StringParam,
    status: StringParam,
    responders: DefaultArray,
    owners: DefaultArray,
  });
  const [streamLoading, setStreamLoading] = useState<
    Record<GroupFilterOptions, number>
  >({ mine: 0, all: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IData>({ mine: [], all: [] });
  const [error, setError] = useState<Error>();

  const fromMy = dayjs().subtract(1, 'year').startOf('quarter');
  const fromAll = dayjs().subtract(6, 'months').startOf('quarter');
  const to = dayjs();

  const {
    value: { userTeams },
    loading,
  } = useUserTeams();

  useEffect(() => {
    async function fetchIncidents() {
      setData({ mine: [], all: [] });
      if (!userTeams?.length) return;
      const teams = await opsGenieApi
        .getTeams()
        .then(res =>
          res.reduce(
            (acc, cur) => acc.set(cur.id, cur),
            new Map<string, Team>(),
          ),
        )
        .catch(() => undefined);

      const filterFn = (i: Incident) => !i.tags.includes('test');

      function mapFn(i: Incident): IncidentWithTeams {
        if (!teams) return { ...i, responders: [] };
        return {
          ...i,
          responders: i.responders
            .map(r => teams.get(r.id))
            .filter(Boolean) as Team[],
        };
      }

      async function readStream(
        stream: ReadableStream<Incident[]>,
        setterKey: GroupFilterOptions,
      ) {
        setStreamLoading(prev => ({
          ...prev,
          [setterKey]: getProgress(prev[setterKey]),
        }));
        const reader = stream.getReader();
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            setStreamLoading(prev => ({ ...prev, [setterKey]: 0 }));
            break;
          }

          const batch = value.filter(filterFn).map(mapFn);
          setData(prev => ({
            ...prev,
            [setterKey]: prev[setterKey].concat(batch),
          }));
          setStreamLoading(prev => ({
            ...prev,
            [setterKey]: getProgress(prev[setterKey]),
          }));
          setIsLoading(false);
        }
      }

      const myIncidentsStream = opsGenieApi.getIncidentsStream({
        limit: 100,
        query: `createdAt < ${to.valueOf()} AND createdAt > ${fromMy.valueOf()}`,
        teams: userTeams.map((item: { spec: { id: any } }) => item.spec.id),
      });
      readStream(myIncidentsStream, 'mine');

      const allIncidentsStream = opsGenieApi.getIncidentsStream({
        limit: 100,
        query: `createdAt < ${to.valueOf()} AND createdAt > ${fromAll.valueOf()}`,
      });
      readStream(allIncidentsStream, 'all');
    }

    setIsLoading(true);
    fetchIncidents()
      .catch(setError)
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTeams]);

  const { value: groups, loading: groupsLoading } = useAsync(
    (): Promise<IEntityGroup[]> =>
      catalogApi
        .getEntities({ filter: { kind: 'Group', 'spec.type': 'team' } })
        .then(res => res.items as IEntityGroup[]),
    [],
  );

  const availableResponders = useMemo((): IOption[] => {
    if (!data?.all.length) return [];
    const list = new Set<string>();
    data.all.forEach(i => i.responders.forEach(r => list.add(r.name)));
    return Array.from(list).map(i => ({ value: i, label: i }));
  }, [data]);

  const availableOwners = useMemo((): IOption[] => {
    if (!data?.all.length || !groups?.length) return [];
    const list = new Set<string>();
    data.all.forEach(i =>
      list.add(String(i.extraProperties['Owning Team radical agility id'])),
    );
    const matchingGroups = groups.filter(t => list.has(t.spec.id));
    return matchingGroups.map(t => ({
      value: t.spec.id,
      label: getTeamOptionName(t),
    }));
  }, [data, groups]);

  const incidents = useMemo(() => {
    let list = cloneDeep(view === 'mine' ? data?.mine : data?.all) ?? [];

    const { severity, status, owners, responders } = queryParams;
    if (severity) {
      list = list.filter(i => i.tags.includes(severity));
    }
    if (status) {
      list = list.filter(i => i.status === status);
    }
    if (owners?.length) {
      list = list.filter(i =>
        (owners as string[]).includes(
          i.extraProperties['Owning Team radical agility id'],
        ),
      );
    }
    if (responders?.length) {
      list = list.filter(i =>
        i.responders.some(r => (responders as string[]).includes(r.name)),
      );
    }

    return list;
  }, [queryParams, view, data]);

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  const from = view === 'mine' ? fromMy : fromAll;
  const title =
    view === 'mine'
      ? 'This Table cover one year worth of incidents, from the current quarter to the same quarter last year.'
      : 'This Table cover 6 months worth of incidents, from the current quarter to the same quarter 6 months ago.';

  return (
    <ContentWrapper>
      <Grid>
        <Card
          id="category-filter"
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.11)',
            boxShadow: 'none',
          }}
        >
          <GroupFilters<GroupFilterOptions>
            selected={view}
            onClick={() => setView('mine')}
            groups={[
              {
                label: 'Personal',
                filters: [
                  {
                    id: 'mine',
                    label: 'For my applications',
                    icon: <SettingsIcon fontSize="small" />,
                    count: data?.mine.length ?? 0,
                    loading: getProgressPercentage(streamLoading.mine),
                  },
                ],
              },
            ]}
          />
          <GroupFilters<GroupFilterOptions>
            selected={view}
            onClick={() => setView('all')}
            groups={[
              {
                label: 'Zalando',
                filters: [
                  {
                    id: 'all',
                    label: 'For All applications',
                    icon: <SettingsIcon fontSize="small" />,
                    count: data?.all.length ?? 0,
                    loading: getProgressPercentage(streamLoading.all),
                  },
                ],
              },
            ]}
          />
        </Card>
        <Box
          display="flex"
          flexDirection="column"
          marginTop="1.5rem"
          rowGap="0.5rem"
          padding="0.5rem"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="h6" style={{ margin: 0, fontSize: '0.75rem' }}>
              Refine Results
            </Typography>
            <Button
              onClick={() =>
                setQueryParams({
                  severity: null,
                  status: null,
                  responders: [],
                  owners: [],
                })
              }
              style={{ fontSize: '0.75rem', padding: 0, minWidth: 'auto' }}
              variant="text"
            >
              Clear
            </Button>
          </Box>

          <SelectFilter
            label="Severity"
            options={severityOptions}
            value={queryParams.severity ?? ''}
            onChange={val => setQueryParams({ severity: val || null })}
            loading={loading || isLoading}
          />
          <SelectFilter
            label="Status"
            options={statusOptions}
            value={queryParams.status ?? ''}
            onChange={val => setQueryParams({ status: val || null })}
            loading={loading || isLoading}
          />
          <MultiSelectFilter
            label="Responders"
            options={availableResponders}
            value={(queryParams.responders.filter(Boolean) as string[]) ?? []}
            onChange={val => setQueryParams({ responders: val })}
            loading={loading || isLoading}
          />
          <MultiSelectFilter
            label="Owners"
            options={availableOwners}
            value={(queryParams.owners.filter(Boolean) as string[]) ?? []}
            onChange={val => setQueryParams({ owners: val })}
            loading={loading || isLoading || groupsLoading}
          />
        </Box>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <InfoPanel
            title={title}
            message={
              <ul>
                <li>Incidents from {from.format('LL')} to now are used</li>
              </ul>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <IncidentsTable
            incidents={incidents}
            loading={loading || isLoading}
          />
        </Grid>
      </Grid>
    </ContentWrapper>
  );
}
