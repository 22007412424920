import { OpsgenieApi, opsgenieApiRef } from './api';
import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import { oauth2ApiRef } from 'plugin-core';
import {
  AnalitycsApi,
  analyticsApiRef,
  DEFAULT_BUSINESS_HOURS_END,
  DEFAULT_BUSINESS_HOURS_START,
} from './analytics';
import { rootRouteRef } from './routes';
import { OperationBasedSLOsApi, operationBasedSLOsApiRef } from './sloapi';

export const opsgeniePlugin = createPlugin({
  id: 'opsgenie',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: opsgenieApiRef,
      deps: { discoveryApi: discoveryApiRef, configApi: configApiRef },
      factory: ({ discoveryApi, configApi }) => {
        return new OpsgenieApi({
          discoveryApi: discoveryApi,
          domain: configApi.getString('opsgenie.domain'),
          readOnly: configApi.getOptionalBoolean('opsgenie.readOnly') ?? false,
          proxyPath: configApi.getOptionalString('opsgenie.proxyPath'),
        });
      },
    }),

    createApiFactory({
      api: analyticsApiRef,
      deps: { configApi: configApiRef },
      factory: ({ configApi }) => {
        return new AnalitycsApi({
          businessHours: {
            start:
              configApi.getOptionalNumber(
                'opsgenie.analytics.businessHours.start',
              ) || DEFAULT_BUSINESS_HOURS_START,
            end:
              configApi.getOptionalNumber(
                'opsgenie.analytics.businessHours.end',
              ) || DEFAULT_BUSINESS_HOURS_END,
          },
        });
      },
    }),

    createApiFactory({
      api: operationBasedSLOsApiRef,
      deps: { discoveryApi: discoveryApiRef, oauth2Api: oauth2ApiRef },
      factory: ({ discoveryApi, oauth2Api }) => {
        return new OperationBasedSLOsApi({ discoveryApi, oauth2Api });
      },
    }),
  ],
});

export const OpsgeniePage = opsgeniePlugin.provide(
  createRoutableExtension({
    name: 'OpsgeniePage',
    component: () =>
      import('./components/OpsgeniePage').then(m => m.OpsgeniePage),
    mountPoint: rootRouteRef,
  }),
);
