import type { TableFilterOption } from './types/filtering';

export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_PAGE_SIZE_OPTIONS = [5, 10, 20, 50, 100];
export const DEFAULT_EMPTY_TEXT = 'No records to display';
/** MUI spacing units */
export const DEFAULT_TREE_SPACING = 3.5;

// Filter Options
export const DEFAULT_BOOLEAN_FILTER_OPTIONS: TableFilterOption[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];
export const DEFAULT_EXISTS_FILTER_OPTIONS: TableFilterOption[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];
export const FILTERS_WIDTH = '250px';
