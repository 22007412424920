import { stringifyEntityRef } from '@backstage/catalog-model';

export const SCORECARD_CATEGORIES = [
  'Operational Excellence',
  'APEC',
  'Dependencies',
];

export function calculateCompletionMetadata(
  scorecard: IEntityScorecard,
  allAssessments: IEntityScorecardAssessmentSpec['assessment'][],
) {
  const assessments = allAssessments.filter(
    a => a.scorecard === stringifyEntityRef(scorecard),
  );
  let failing = 0;
  let passing = 0;
  let completionPercentage = 0;

  assessments.forEach(assessment => {
    if (assessment.checks) {
      [failing, passing] = assessment.checks.reduce(
        (acc, check) => {
          if (check.success) {
            acc[1]++;
          } else acc[0]++;
          return acc;
        },
        [failing, passing],
      );
    }
  });

  if (failing + passing > 0)
    completionPercentage = Math.round((passing / (failing + passing)) * 100);

  return { completionPercentage, failing, passing };
}

export function addUrlParams({
  baseUrl,
  teams,
  scorecardStatus,
  targetClassification,
}: {
  baseUrl: string;
  teams?: IEntityGroup[];
  scorecardStatus?: string;
  targetClassification?: string;
}) {
  let url = baseUrl;
  const searchParams = new URLSearchParams();

  if (teams && teams.length > 0) {
    searchParams.set(
      'teams',
      teams
        .map(t => t.spec.id)
        .filter(Boolean)
        .join(','),
    );
  }
  if (scorecardStatus) {
    searchParams.set('scorecardStatus', scorecardStatus);
  }

  if (targetClassification) {
    searchParams.set('targetClassification', targetClassification);
  }
  url += `?${searchParams.toString()}`;

  return url;
}
